import { createApp } from 'vue'
import App from './App.vue';
const app = createApp(App);
//引入router
import router from './router/index';
//引入element-plus和css
import ElementPlus, { ElMessage } from "element-plus";
import "element-plus/dist/index.css";
import zhLocale from "../node_modules/element-plus/lib/locale/lang/zh-cn";
import vuetyped from "vue3typed";
// 全局挂载 ElMessage
app.config.globalProperties.$message = ElMessage;

//引入使用icon图标
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}

app.use(ElementPlus, { locale: zhLocale });
app.use(router)
app.use(vuetyped);
app.mount('#app')
