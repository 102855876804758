<template>
  <div id="app">
    <router-view></router-view>
  </div>

</template>

<script setup>

</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

html {
  font-size: calc(100vw/3.75);
}

body {
  font-size: 16px;
}
</style>
