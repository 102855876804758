import { createRouter, createWebHashHistory } from "vue-router";
//基础理由
const route = [
  {
    path: "/",
    redirect: "/index", // 将根路径重定向到 /login
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/InDex.vue"),
  },
  {
    path: "/phone",
    name: "phone",
    component: () => import("@/views/PhOne.vue"),
  },
  {
    path:'/check',
    name:'check',
    component:()=>import("@/views/ChEck.vue"),
  }
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("@/views/LoGin.vue"),
  // },
];
const router = createRouter({
    history: createWebHashHistory(),
    routes: route,
});

export default router;